import React, { forwardRef, useEffect, useState } from 'react';
import AvatarGroup from '@mui/material/AvatarGroup';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassHalf, faSigma } from '@fortawesome/pro-solid-svg-icons';

import Typography, { TypographyVariant } from '../Typography';
import Tooltip from '../Tooltip';

import { AvatarGroupProps, Spacing } from './AvatarGroup.types';
import AvatarGroupTheme from './AvatarGroup.theme';

const ThemedAvatarGroup = withStyles(AvatarGroupTheme.classes)(AvatarGroup);

const resolveAvatars = (data: Array<any>, max: number) => {
  const sliceCount = data.length > max ? max - 1 : max;

  return {
    inRange: data?.slice(0, sliceCount),
    overflow: data?.slice(sliceCount),
  };
};

const AvatarGroupComponent = forwardRef<HTMLDivElement, AvatarGroupProps>((props, ref) => {
  const {
    id, className, style,
    max = 5, spacing = 8,
    data, item, getAvatarNode,
    overflowCountClassName, overflowCountStyle,
    overflowCountHidden,
    overflowCountHasTooltip, getOverflowCountTolltipContent,
    isCurrentTotal, isAverageTime,
  } = props;
  const [resolvedAvatars, setResolvedAvatars] = useState(resolveAvatars(data, max));

  useEffect(() => {
    setResolvedAvatars(resolveAvatars(data, max))
  }, [data, max]);

  const classes = AvatarGroupTheme.useStyles(props);
  const overflowItems = resolvedAvatars.overflow;
  const overflowItemsAvailable = overflowItems.length > 0;
  const resolvedAvatarGroupClassName =  classnames(classes.avatarGroup, {
    [classes.additionalCountAvailable]: overflowItemsAvailable,
    [classes.additionalCountNotAvailable]: !overflowItemsAvailable,
  }, className);

  const shouldShowOverflowTooltip = overflowCountHasTooltip && getOverflowCountTolltipContent;
  const OverflowCountNode = (
    <div
      id={`${id}-OverflowCount`}
      className={classnames(classes.overflowCountWrapper, overflowCountClassName)}
      style={overflowCountStyle}
    >
      <Typography.H6 variant={TypographyVariant.light}>+{overflowItems.length}</Typography.H6>
    </div>
  );

  return (
    <ThemedAvatarGroup
      id={id}
      ref={ref}
      className={resolvedAvatarGroupClassName}
      style={style}
      max={1000} // We handle the max logic by ourselves
      spacing={spacing}
    >
      {resolvedAvatars.inRange.map((nodeData, index) => getAvatarNode(nodeData, { zIndex: index + 1 }, item) )}

      {/* Showing the overflow count without tooltip */}
      {!overflowCountHidden && overflowItemsAvailable && !shouldShowOverflowTooltip && OverflowCountNode}

      {/* Showing the overflow count with tooltip */}
      {!overflowCountHidden && overflowItemsAvailable && overflowCountHasTooltip && getOverflowCountTolltipContent && (
        <Tooltip
          id={`${id}-OverflowCount-Tooltip-Wrapper`}
          placement="bottom"
          content={getOverflowCountTolltipContent(resolvedAvatars.overflow)}
          arrow
        >
          {OverflowCountNode}
        </Tooltip>
      )}
      {isAverageTime && (<div className={classes.bottomIcon}><FontAwesomeIcon icon={faHourglassHalf} /></div>)}
      {isCurrentTotal && (<div className={classes.bottomIcon}><FontAwesomeIcon icon={faSigma} /></div>)}
    </ThemedAvatarGroup>
  );
});

AvatarGroupComponent.defaultProps = {
  className: undefined,
  style: undefined,
  max: 5,
  spacing: 8,
  item: undefined,
};

export type AvatarGroupSpacing = Spacing;
export default AvatarGroupComponent;
