import classnames from 'classnames';
import React, { forwardRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFireAlt, faCircleUser, faPhone } from '@fortawesome/pro-solid-svg-icons';

import Avatar from '../../Avatar';
import Tooltip from '../../Tooltip';
import { AvatarService } from '../../../Services/Avatar';
import Typography, { TypographyVariant } from '../../Typography';
import Button, { ButtonTheme, ButtonVariant } from '../../Button';
import DropdownButton, { DropdownButtonSplitTheme } from '../../Split';

import ScheduledCallCardThemer from './ScheduledCallCard.theme';
import { ScheduledCallCardProps } from './ScheduledCallCard.types';

const PhoneIcon = (
    <FontAwesomeIcon icon={faPhone} color='white' fontSize='20px' display='inline-block' />
);

const ScheduledCallCardComponent = forwardRef<HTMLDivElement, ScheduledCallCardProps<any>>((props, ref) => {
  const {
    className, style, data,
    getId, getPhotoUrl, getPhotoFallbackLabel,
    getName, getCompanyName, getTime, getDate,
    menuClassName, menuStyle, menuListClassName, menuListStyle,
    onCall, getCampaignColour, getCampaignName,
    getMenuItems, showTime, showCallButton, onContactClick, onScheduleRowClick,
    callButtonDisabled, isUnknownContact
  } = props;
  const classes = ScheduledCallCardThemer.useStyles(props);
  const numbersNotAvailable = !getMenuItems(data);
  const campaignName = getCampaignName(data);
  const companyName = getCompanyName(data);
  const id = getId(data);
  const unknownContact = isUnknownContact(data);

  const handleScheduleRowClick = useCallback((e: any) => {
    e?.stopPropagation();
    onScheduleRowClick?.(data);
  }, [onScheduleRowClick, data]);

  const handleDefaultCallClick = useCallback((e: any) => {
    e?.stopPropagation();
    onCall(data);
  }, [onCall, data]);

  const handleContactClick = useCallback((e: any) => {
    e?.stopPropagation();
    onContactClick(data);
  }, [data, onContactClick]);

  const avatarStyle = {
    backgroundColor: AvatarService.getBackgroundColour(getPhotoFallbackLabel(data), null),
  };
  const fallbackTextStyle = {
    color: AvatarService.getTextColour(getPhotoFallbackLabel(data), null),
  };

  return (
    <div
      id={id}
      ref={ref}
      className={classnames(className, classes.container)}
      style={style}
      onClick={handleScheduleRowClick}
    >
      <div className={classes.contactAvtar}>
        {unknownContact && <FontAwesomeIcon icon={faCircleUser} color="#08104D" className={classes.unknownContactIcon} />}
        {!unknownContact && (<Avatar
            id={`${id}-Avatar`}
            style={avatarStyle}
            fallbackTextStyle={fallbackTextStyle}
            url={getPhotoUrl(data)}
            fallbackLabel={getPhotoFallbackLabel(data)}
            alt='Contact Photo'
            className={classes.avatar}
          />
        )}
        <div className={classes.contact}>
          <div>
            <Button
              id={`${id}-ContactName`}
              className={classes.contactNameText}
              variant={ButtonVariant.text}
              theme={ButtonTheme.primary}
              onClick={handleContactClick}
            >
              {getName(data)}
            </Button>            
            {companyName && (
              <Typography.P2
                id={`${id}-ContactTitle`}
                className={classnames(classes.contactCompanyText)}
                variant={TypographyVariant.system}
                noWrap
              >
                {companyName}
              </Typography.P2>
            )}
          </div>
          {campaignName && (
            <div className={classes.toolTipContainer}>
            <Tooltip
              id={`${id}-Campaign-Tooltip`}
              content={<Typography.P2 variant={TypographyVariant.light}>{getCampaignName(data)}</Typography.P2>}
              placement="bottom"
              arrow
              force
            >
            <div
              id={`${id}-IndustryType`}
              className={classes.campaignContainer}
            >
              <FontAwesomeIcon icon={faFireAlt} color={getCampaignColour(data)} className={classes.campaignIcon} />
            </div>
            </Tooltip>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className={classes.scheduledTime}>
          {showTime && (
            <>
              <Typography.H4
                id={`${id}-Date`}
                className={classnames(classes.timeText)}
                variant={TypographyVariant.system}
                noWrap
              >
                {getDate?.(data) ?? '-'}
              </Typography.H4>
              <Typography.P1
                id={`${id}-Time`}
                className={classnames(classes.timeText)}
                variant={TypographyVariant.system}
                noWrap
              >
                {getTime?.(data) ?? '-'}
              </Typography.P1>
            </>
          )}
        </div>
        <div className={classes.callButton}>
          {
            showCallButton && (
              <DropdownButton
                id={`${id}-CallActionButton`}
                menuClassName={classnames(menuClassName)}
                menuStyle={menuStyle}
                menuListClassName={classnames(menuListClassName)}
                menuListStyle={menuListStyle}
                theme={DropdownButtonSplitTheme.secondary}
                startIcon={PhoneIcon}
                disabled={numbersNotAvailable || callButtonDisabled}
                menuItems={getMenuItems(data)}
                onPrimaryButtonClick={handleDefaultCallClick}
                usePopper
              >
                {getTime?.(data) ?? '-'}
              </DropdownButton>
            )
          }
        </div>
      </div>
    </div>
  );
});

ScheduledCallCardComponent.defaultProps = {
  className: undefined,
  style: undefined,
  showTime: true,
  showCallButton: true,
  onCall: () => null,
  onContactClick: () => null,
  callButtonDisabled: false,
};

export default ScheduledCallCardComponent;
