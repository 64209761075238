import React, { forwardRef, useRef, useState, useCallback } from 'react';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Popover from '@material-ui/core/Popover';
import MenuList from '@material-ui/core/MenuList';
import { PopoverOrigin } from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faLoveseat, faAngleUp } from '@fortawesome/pro-solid-svg-icons';

import Button, { ButtonTheme, ButtonVariant } from '../Button';
import Popper from '../Popper';

import { DropdownButtonSplitProps } from './DropdownButtonSplit.types';
import DropdownButtonSplitThemer from './DropdownButtonSplit.theme';

const ThemedContentButton = withStyles(DropdownButtonSplitThemer.contentButtonClasses)(Button);

const DefaultAnchorOrigin: PopoverOrigin = { vertical: 'bottom', horizontal: 'right' };
const DefaultTransformOrigin: PopoverOrigin = { vertical: 'top', horizontal: 'right' };

const DropdownButtonSplitComponent = forwardRef<HTMLDivElement, DropdownButtonSplitProps>((props, ref) => {
  const {
    id,
    contentButtonClassName,
    contentButtonStyle,
    dropButtonClassName,
    dropButtonStyle,
    menuClassName,
    menuStyle,
    menuListClassName,
    menuListStyle,
    startIcon,
    dropIcon,
    theme,
    variant,
    disabled,
    children,
    menuItems,
    anchorOrigin,
    transformOrigin,
    callbackValue,
    onPrimaryButtonClick,
    usePopper,
  } = props;
  const classes = DropdownButtonSplitThemer.useStyles();
  const elementRef = useRef<any>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handlePrimaryButtonClick = useCallback(( cb: any, e: any) => {
    onPrimaryButtonClick?.(callbackValue, e);
  }, [callbackValue, onPrimaryButtonClick]);

  const handleDropButtonClick = useCallback(( cb: any, e: any) => {
    e.stopPropagation();
    setMenuOpen(!menuOpen);
  }, [menuOpen]);
  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
  }, []);

  const dropdownIcon = menuOpen ? (
      <FontAwesomeIcon icon={faAngleUp} color='white' /> 
  ) : (  
      <FontAwesomeIcon icon={faAngleDown} color='white' />  
  );

  return (
    <div id={id} ref={ref}>
      <ButtonGroup id={`${id}-ButtonGroup`} ref={elementRef}>
        <ThemedContentButton
          id={`${id}-ButtonGroup-ContentButton`}
          className={classnames(classes.contentButton, contentButtonClassName)}
          style={contentButtonStyle}
          startIcon={startIcon}
          theme={theme}
          variant={variant}
          onClick={handlePrimaryButtonClick}
          disabled={disabled}
        >
          {children}
        </ThemedContentButton>
        <Button
          id={`${id}-ButtonGroup-ContentButton`}
          className={classnames(classes.dropButton, dropButtonClassName)}
          style={dropButtonStyle}
          theme={theme}
          variant={variant}
          onClick={handleDropButtonClick}
          disabled={disabled}
        >
          {dropIcon ?? dropdownIcon}
        </Button>
      </ButtonGroup>

      {usePopper && (
        <Popper
          id={id}
          className={classes.popper}
          anchorEl={elementRef.current}
          open={menuOpen}
          onClose={handleMenuClose}
          menuItems={menuItems}
          menuListClassName={classnames(classes.menuList, menuListClassName)}
          menuListStyle={menuListStyle}
          disablePortal
          transition
        />
      )}
      {!usePopper && (
        <Popover
          id={`${id}-Menu`}
          className={classnames(classes.menu, menuClassName)}
          style={menuStyle}
          anchorEl={elementRef.current}
          open={menuOpen}
          onClick={handleMenuClose}
          onClose={handleMenuClose}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          <MenuList
            id={`${id}-Menu-List`}
            className={classnames(classes.menuList, menuListClassName)}
            style={menuListStyle}
            onClick={handleMenuClose}
          >
            {menuItems}
          </MenuList>
        </Popover>
      )}
    </div>
  );
});

DropdownButtonSplitComponent.defaultProps = {
  contentButtonClassName: undefined,
  contentButtonStyle: undefined,
  dropButtonClassName: undefined,
  dropButtonStyle: undefined,
  menuClassName: undefined,
  menuStyle: undefined,
  menuListClassName: undefined,
  menuListStyle: undefined,
  theme: ButtonTheme.primary,
  variant: ButtonVariant.normal,
  anchorOrigin: DefaultAnchorOrigin,
  transformOrigin: DefaultTransformOrigin,
  callbackValue: null,
  onPrimaryButtonClick: () => null,
  usePopper: false,
};

export { DropdownButtonSplitTheme, DropdownButtonSplitVariant } from './DropdownButtonSplit.types';
export default DropdownButtonSplitComponent;
