import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

import SummaryCardRoot from './SummaryCardRoot';
import IconTitle from '../IconTitle';
import Typography, { TypographyVariant } from '../Typography';
import CallHistory from '../CallHistory';

import { SummaryCardCallHistoryProps } from './SummaryCardCallHistory.types';
import SummaryCardCallHistoryThemer from './SummaryCardCallHistory.theme';

const Title = (props:any) => {
  const { textColor, title, historyIconOnClick, renderHistoryIcon } = props;

  return (
    <IconTitle
      icon={renderHistoryIcon}
      iconBackgroundColor="rgb(250, 154, 113)"
      iconOnClick={historyIconOnClick}
    >
      <Typography.H3 style={{ color: textColor, paddingLeft: 10 }} variant={TypographyVariant.active}>
        {title ??  `Call History`}
      </Typography.H3>
    </IconTitle>
  );
};

const SummaryCardCallHistoryComponent = forwardRef<HTMLDivElement, SummaryCardCallHistoryProps<any, any>>((props, ref) => {
  const {
    className, style, title,
    footerClassName, headerClassName, callHistoryContainer,
    bodyClassName, data, dataTotalCount, dataLoading,
    id, bottomHitThreshold, children, getId, getAvatarUrl,
    getAvatarFallbackLabel, isFailedCall, getCallDirection, getCallMedium,
    getDate, getTime, getDuration, actionsMaxCount,
    getActions, getActionNode, showActionsOverflowTooltip,
    getActionsOverflowCountTooltipContent, showNavigationNode,
    showNavNodeOnHover, getNavigationNode,
    onNextPageRequested, getAvatarClassName, isUnwrapped,
    showCallActionButton, menuClassName, menuListClassName, menuStyle, menuListStyle, getMenuItems, onCall, getCallStatusBadgeTooltip,
    onHistoryRowClick, onAvatarClick, historyIconOnClick,
  } = props;
  const classes = SummaryCardCallHistoryThemer.useStyles(props);

  return (
    <SummaryCardRoot
      id={`${id}-SummaryCard-CallHistory`}
      className={className}
      style={style}
      headerClassName={classnames(headerClassName)}
      footerClassName={classnames(footerClassName, classes.historyFooter)}
      bodyClassName={classnames(bodyClassName, classes.summaryHistoryBody)}
      header={
        <Title
          title={title}
          iconBgColor='#FA9A71'
          textColor='#000000'
          historyIconOnClick={historyIconOnClick}
          renderHistoryIcon={
          <>
            {dataLoading && 
              (
                <FontAwesomeIcon icon={faHistory} color={'white'} spin spinReverse/>
            )}
            {!dataLoading && 
              (
                <FontAwesomeIcon icon={faHistory} color={'white'}/>
            )}
          </>}
        />
      }
      footer={
        <>
        </>
      }
    >
      <div
        id={id}
        ref={ref}
        className={classnames(className)}
        style={style}
      >
        <CallHistory
          id={`${id}-SummaryCallHistory`}
          className={classnames(callHistoryContainer, classes.historyContainer)}
          data={data}
          dataTotalCount={dataTotalCount}
          dataLoading={dataLoading}
          bottomHitThreshold={bottomHitThreshold}
          onNextPageRequested={onNextPageRequested}
          getId={getId}
          getAvatarUrl={getAvatarUrl}
          getAvatarFallbackLabel={getAvatarFallbackLabel}
          getAvatarClassName={getAvatarClassName}
          isFailedCall={isFailedCall}
          isUnwrapped={isUnwrapped}
          getCallDirection={getCallDirection}
          getCallMedium={getCallMedium}
          getDate={getDate}
          getTime={getTime}
          getDuration={getDuration}
          actionsMaxCount={actionsMaxCount}
          getActions={getActions}
          getActionNode={getActionNode}
          showActionsOverflowTooltip={showActionsOverflowTooltip}
          getActionsOverflowCountTooltipContent={getActionsOverflowCountTooltipContent}
          showNavigationNode={showNavigationNode}
          showCallActionButton={showCallActionButton}
          showNavNodeOnHover={showNavNodeOnHover}
          getNavigationNode={getNavigationNode}
          showHeader={false}
          miniVersion
          menuClassName={menuClassName}
          menuListClassName={menuListClassName}
          menuStyle={menuStyle}
          menuListStyle={menuListStyle}
          onCall={onCall}
          getMenuItems={getMenuItems}
          onHistoryRowClick={onHistoryRowClick}
          onAvatarClick={onAvatarClick}
          getCallStatusBadgeTooltip={getCallStatusBadgeTooltip}
        />
        {children}
      </div>
    </SummaryCardRoot>
  );
});

SummaryCardCallHistoryComponent.defaultProps = {
  className: undefined,
  style: undefined,
  title: undefined,
  headerClassName: undefined,
  footerClassName: undefined,
  bodyClassName: undefined,
  callHistoryContainer: undefined,
  data: [],
  dataTotalCount: 0,
  dataLoading: false,
  onViewMoreClick: () => null,
  href: '',
  showNavNodeOnHover: false,
};

export default SummaryCardCallHistoryComponent;
