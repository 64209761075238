import React, { forwardRef, useCallback } from 'react';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

import IconButton, { IconButtonTheme, IconButtonSize } from '../IconButton';
import Icon, { IconTheme, IconSize } from '../Icon';

import { SearchInputProps, Theme, Variant } from './SearchInput.types';
import SearchInputThemer from './SearchInput.theme';

const ClearIconButtonThemeMap = {
  [Theme.default]: IconButtonTheme.dark,
  [Theme.primary]: IconButtonTheme.dark,
  [Theme.secondary]: IconButtonTheme.dark,
  [Theme.inverse]: IconButtonTheme.light,
};
const ClearIconThemeMap = {
  [Theme.default]: IconTheme.dark,
  [Theme.primary]: IconTheme.dark,
  [Theme.secondary]: IconTheme.dark,
  [Theme.inverse]: IconTheme.light,
};

const SearchInputComponent = forwardRef<HTMLDivElement, SearchInputProps>((props, ref) => {
  const {
    id, className, style, theme,
    disabled,
    callbackValue,
    placeHolder,
    value, onChange,
    onFocus, onBlur, onClear,
    showClearButton, showClearIconButton, startAdornmentNode,
    autoComplete, onKeyPress,
  } = props;
  const classes = SearchInputThemer.useStyles(props);
  const defaultInputClasses = SearchInputThemer.useDefaultTextFieldStyles(props);
  const primaryInputClasses = SearchInputThemer.usePrimaryTextFieldStyles(props);
  const secondaryInputClasses = SearchInputThemer.useSecondaryTextFieldStyles(props);
  const inverseInputClasses = SearchInputThemer.useInverseTextFieldStyles(props);
  const themeClassesMap = {
    [Theme.default]: defaultInputClasses,
    [Theme.primary]: primaryInputClasses,
    [Theme.secondary]: secondaryInputClasses,
    [Theme.inverse]: inverseInputClasses,
  };

  const handleChange = useCallback((event: any) => {
    onChange(event, callbackValue);
  }, [onChange, callbackValue]);
  const handleFocus = useCallback((event: any) => {
    onFocus?.(event, callbackValue);
  }, [onFocus, callbackValue]);
  const handleBlur = useCallback((event: any) => {
    onBlur?.(event, callbackValue);
  }, [onBlur, callbackValue]);

  return (
    <OutlinedInput
      id={id}
      ref={ref}
      notched
      disabled={!!disabled}
      classes={themeClassesMap[theme || Theme.primary]}
      className={classnames(className, classes.searchInput)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      style={style}
      label={undefined}
      placeholder={placeHolder}
      autoComplete={autoComplete}
      onKeyPress={onKeyPress}
      startAdornment={(
        <InputAdornment position="start">
          {startAdornmentNode}
        </InputAdornment>
      )}
      endAdornment={showClearButton && (
        <InputAdornment position="end">
          {showClearIconButton && (
            <IconButton
              id={`${id}-ClearButton`}
              theme={ClearIconButtonThemeMap[theme || Theme.primary]}
              size={IconButtonSize.small}
              onClick={onClear}
              callbackValue={callbackValue}
              disabled={disabled}
            >
              <Icon style={{color: '#4C4C4C'}} size={IconSize.massive}>
                cancel
              </Icon>
            </IconButton>
          )}
          {!showClearIconButton && (
            <IconButton
              id={`${id}-ClearButton`}
              theme={ClearIconButtonThemeMap[theme || Theme.primary]}
              size={IconButtonSize.small}
              onClick={onClear}
              callbackValue={callbackValue}
              disabled={disabled}
            >
              <Icon theme={ClearIconThemeMap[theme || Theme.primary]} size={IconSize.small}>
                clear
              </Icon>
            </IconButton>
          )}
        </InputAdornment>
      )}
      value={value}
      onChange={handleChange}
    />
  );
});

SearchInputComponent.defaultProps = {
  className: undefined,
  style: undefined,
  theme: Theme.primary,
  variant: Variant.rounded,
  disabled: false,
  callbackValue: undefined,
  placeHolder: 'Type to Search',
  showClearButton: false,
  showClearIconButton: false,
  autoComplete: 'off',
  startAdornmentNode: (<FontAwesomeIcon icon={faSearch} size='lg' />),
  onFocus: () => null,
  onBlur: () => null,
  onClear: () => null,
  onKeyPress: () => null,
};

export { Theme as SearchInputTheme, Variant as SearchInputVariant };
export default SearchInputComponent;
