import BrowserApiService from '../BrowserApi';
import SaleforceService  from '../../Services/SaleforceService';

const LogTitle = 'CallNotificationService';

const NotificationParams = {
  /**
   * Whether to show the incoming call notification
   */
  shouldShow: false,
};

class CallNotificationService {
  registerPageVisibilityListener = () => {
    console.log(LogTitle, 'Registering Page Visibility listener for Call Notifications');
    BrowserApiService.Visibility.registerListener(this.handlePageVisibilityChange);
  }

  handlePageVisibilityChange = () => {
    const pageBecameVisible = !document[(BrowserApiService.Visibility.visibilityParams.hidden as keyof Document)];
    console.log(LogTitle, 'Page visibility change detected. Invoking listener');
    if (pageBecameVisible) this.handlePageGainedVisibility();
    else this.handlePageLostVisibility();
  }

  handlePageGainedVisibility = () => {
    console.log(LogTitle, 'Page has come back to focus. Skipping Browser notifications');
    NotificationParams.shouldShow = false;
  }

  handlePageLostVisibility = () => {
    console.log(LogTitle, 'Page has lost focus. Will be showing Browser notifications');
    NotificationParams.shouldShow = true;
  }

  showIncomingCallNotification = (callDetails: any, caller: any, displayName: any) => {
    if (!NotificationParams.shouldShow) {
      console.log(LogTitle, 'Incoming call detected. Browser notification deemed unnecessary. Skipping');
      return;
    }
    console.log(LogTitle, 'Incoming call detected. Browser notification Send for showIncomingCallNotification');

    SaleforceService.publishObject({
      type: 'DESKTOP_NOTIFICATION', 
      title: 'Incoming Call', 
      callDetails: callDetails,
      caller: caller,
      displayName: displayName,
      refId: callDetails?.callRecordId,
      status: true
    }, "rocketphone__NOTIFICATIONMC__c");
  }

  hideIncomingCallNotification = (callRecordId: string) => {
    console.log(LogTitle, 'Incoming call detected. Browser notification Send hideIncomingCallNotification');

    SaleforceService.publishObject({
      type: 'DESKTOP_NOTIFICATION', 
      refId: callRecordId,
      status: false
    }, "rocketphone__NOTIFICATIONMC__c");
  }
}

export default new CallNotificationService();