import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = { data: {} };

const callingContactSlice = createSlice({
  name: 'callingContact',
  initialState,
  reducers: {
    addCallingContact(state, action: PayloadAction<any>) {
      state.data = action.payload;
    },
    clearCallingContact(state) {
      state.data = {};
    },
  },
});

export const { addCallingContact, clearCallingContact } = callingContactSlice.actions;

export default callingContactSlice.reducer;
