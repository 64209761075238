import React, { forwardRef, useCallback } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { faBookmark, faGripDots, faPause, faPhone, faPhoneHangup } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

import { CallState } from '../../Enums/Call.types';
import CallForwardCard from '../CallForwardCard';

import Buttons, { CallButtonTheme, CallIconButtonSize } from './private/Buttons';
import NumberDropdown from './private/NumberDropdown';
import Dialpad from './private/Dialpad';
import ContactView from './private/ContactView';
import Numberpad from './private/NumberPad';
import {
  NavigationDiallerProps,
  CallButtonVisibleMap,
  CallControlButtonsVisibleMap,
  NumberDropDownDisabledMap,
  ContactViewVisibleMap,
  ContactNameViewVisibleMap,
} from './NavigationDialler.types';
import NavigationDiallerThemer from './NavigationDialler.theme';

const ThemedCard = withStyles(NavigationDiallerThemer.classes)(Card);
const ThemedCardContent = withStyles(NavigationDiallerThemer.cardContentClasses)(CardContent);

const NavigationDiallerComponent = forwardRef<HTMLElement, NavigationDiallerProps<any, any>>((props, ref) => {
  const {
    id,
    className,
    menuZIndex,
    style,
    callState,
    isCallRecording,
    contactNumbers,
    moreNumbers,
    showMoreNumber,
    moreNumberOpened,
    onDropDownSelect,
    showBackSpace,
    number,
    callButtonDisabled,
    dialpadButtonDisabled,
    recordingButtonDisabled,
    onDialpadNumberPressed,
    onNumberChanged,
    numberCallbackValue,
    onBackspaceClick,
    showDialpad,
    isMicMute,
    isOnHold,
    contactName,
    contactCount,
    callTime,
    onCallClicked,
    onHangUp,
    onCallRecord,
    onAddBookmark,
    onDialpadClicked,
    onCallHold,
    onCallMute,
    onCallForwardButtonClicked,
    showCallCenterButton,
    onGoToCallCenter,
    onClose,
    getMoreNumberValue, getMoreNumberIcon, isMoreNumberSelected,
    onMoreNumberPanelExpand,
    onMoreNumberItemClick,
    showCallForwardCard,
    hideNumberPad,
    title,
    proceedButtonLabel,
    proceedButtonDisabled,
    onCallForwardCardClose,
    inputValue,
    onInputChange,
    dataLoading,
    onProceed,
    onSelect,
    data,
    dataTotalCounts,
    getId,
    getAvatarUrl,
    getAvatarFallbackLabel,
    getContactName,
    getContactStatus,
    isSelected,
    isActive,
    dialpadOpen,
    externalNumber,
    dialpadProceedButtonDisabled,
    onUseDialpadClicked,
    onCallForwardNumberChange,
    onCallForwardNumberPressed,
    onCallForwardBackSpaceClicked,
    onCallForwardBackButtonClicked,
    onDialpadProceed,
    onCursorPositionChange,
  } = props;

  const classes = NavigationDiallerThemer.useStyles();
  const dropdownDisabled = NumberDropDownDisabledMap[callState];
  const showContactView = ContactViewVisibleMap[callState] && !showDialpad;
  const showCallButton = CallButtonVisibleMap[callState];
  const showCallControls = CallControlButtonsVisibleMap[callState];
  const showContactName = ContactNameViewVisibleMap[callState];
  const callControlButtonsDisabled = callState === CallState.ended;
  const actionButtonDisable = callState !== CallState.answered;

  const extendedClassnames = contactName && showContactName;
  const numberPadClassnames = classnames(classes.numberPad, extendedClassnames && classes.numberPadExtended);
  const bodyContainerClassnames = classnames(classes.bodyContainer, extendedClassnames && classes.bodyContainerExtended);

  const handleBackSpaceClick = useCallback(
    (value: any, e: any) => {
      onBackspaceClick?.(value, e);
    },
    [onBackspaceClick]
  );

  const handleMoreNumberPanelClick = useCallback(() => {
    onMoreNumberPanelExpand?.(moreNumberOpened);
  }, [moreNumberOpened, onMoreNumberPanelExpand]);

  const handleNestedNumberClick = useCallback((number: any) => {
    onMoreNumberItemClick?.(number)
  }, [onMoreNumberItemClick])

  return (
    <div className={classes.mainContainer}>
      <div className={classes.diallerContainer}>
        <ThemedCard id={id} ref={ref} className={className} style={style}>
          <ThemedCardContent>
            <div className={classes.headerContainer}>
              <NumberDropdown
                id={`${id}-Number-Dropdown`}
                menuItems={contactNumbers}
                dropdownDisabled={dropdownDisabled}
                onDropDownSelect={onDropDownSelect}
                menuZIndex={menuZIndex}
              />
            </div>
            {!showCallForwardCard && (
              <>
                <div className={bodyContainerClassnames}>
                  {!hideNumberPad && (
                    <div className={classes.numberAndContactContainer}>
                      <div className={classes.numberInputContainer}>
                        <Numberpad
                          id={`${id}-Numberpad`}
                          className={numberPadClassnames}
                          number={number}
                          callbackValue={numberCallbackValue}
                          showBackSpace={showBackSpace}
                          onBackspaceClick={handleBackSpaceClick}
                          onNumberChange={onNumberChanged}
                          onCursorPositionChange={onCursorPositionChange}
                          disableFocus={!showDialpad}
                          onEscape={onClose}
                          showContactName={showContactName}
                          contactName={contactName}
                          contactCount={contactCount}
                        />
                      </div>
                    </div>
                  )}
                  {showDialpad && (
                    <Dialpad id={`${id}-Dialpad`} numberCallbackValue={numberCallbackValue} onNumberChange={onDialpadNumberPressed} dialpadButtonDisabled={dialpadButtonDisabled} />
                  )}
                  {showContactView && (
                    <ContactView
                      id={`${id}-Contact-View`}
                      callState={callState}
                      isCallRecording={isCallRecording}
                      actionButtonDisable={actionButtonDisable}
                      isMicMute={isMicMute}
                      isOnHold={isOnHold}
                      callTime={callTime}
                      contactCount={contactCount}
                      onCallHold={onCallHold}
                      onCallMute={onCallMute}
                      onCallForwardButtonClicked={onCallForwardButtonClicked}
                    />
                  )}
                </div>
                <div className={classes.actionsContainer}>
                  {showCallButton && (
                    <div className={classes.callButtonContainer}>
                      <Buttons.DiallerCallActionButton
                        id={`${id}-Call-Button`}
                        className={classes.callButton}
                        theme={CallButtonTheme.secondary}
                        fullWidth
                        onClick={onCallClicked}
                        disabled={callButtonDisabled}
                      >
                        <FontAwesomeIcon icon={faPhone} className={classes.callIcon} />
                      </Buttons.DiallerCallActionButton>
                    </div>
                  )}
                  {showCallControls && (
                    <div className={classes.callControlsContainer}>
                      <div className={classes.callButtonsWrapper}>
                        <Buttons.CallControlButton
                          id={`${id}-Call-Control-Button`}
                          className={classes.callControlButton}
                          theme={CallButtonTheme.danger}
                          size={CallIconButtonSize.large}
                          onClick={onHangUp}
                          disabled={callControlButtonsDisabled}
                        >
                          <FontAwesomeIcon icon={faPhoneHangup} style={{color: "#ffffff", fontSize: 18}} />
                        </Buttons.CallControlButton>
                        <Buttons.CallControlButton
                          id={`${id}-Call-Record-Button`}
                          className={classes.callControlButton}
                          theme={CallButtonTheme.danger}
                          size={CallIconButtonSize.large}
                          onClick={onCallRecord}
                          disabled={recordingButtonDisabled || callControlButtonsDisabled || actionButtonDisable || isOnHold}
                        >
                          {!isCallRecording ? (
                            'REC'
                          ) : (
                            <FontAwesomeIcon icon={faPause} />
                          )}
                        </Buttons.CallControlButton>
                        <Buttons.CallControlButton
                          id={`${id}-Call-Bookmark-Button`}
                          className={classes.callControlButton}
                          theme={CallButtonTheme.secondaryAlternative}
                          size={CallIconButtonSize.large}
                          onClick={onAddBookmark}
                          // disabled={callControlButtonsDisabled || actionButtonDisable || isOnHold}
                          disabled
                        >
                          <FontAwesomeIcon icon={faBookmark} style={{color: "#ffffff", fontSize: 12}} />
                        </Buttons.CallControlButton>
                        <Buttons.CallControlButton
                          id={`${id}-Dialpad-Button`}
                          className={classes.callControlButton}
                          theme={CallButtonTheme.default}
                          size={CallIconButtonSize.large}
                          onClick={onDialpadClicked}
                          disabled={callControlButtonsDisabled}
                        >
                          <FontAwesomeIcon icon={faGripDots} style={{color: "#ffffff",}} className={classes.dialpadIcon} />
                        </Buttons.CallControlButton>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
            {showCallForwardCard && (
              <CallForwardCard
                id={`${id}-CallForward-Card`}
                className={classes.cardContainer}
                hideHeader={true}
                title={title}
                proceedButtonLabel={proceedButtonLabel}
                proceedButtonDisabled={proceedButtonDisabled}
                inputValue={inputValue}
                onInputChange={onInputChange}
                dataLoading={dataLoading}
                miniSkeleton={true}
                onClose={onCallForwardCardClose}
                onSelect={onSelect}
                onProceed={onProceed}
                data={data}
                dataTotalCounts={dataTotalCounts}
                getId={getId}
                getAvatarUrl={getAvatarUrl}
                getAvatarFallbackLabel={getAvatarFallbackLabel}
                getContactName={getContactName}
                getContactStatus={getContactStatus}
                isSelected={isSelected}
                isActive={isActive}
                dialpadOpen={dialpadOpen}
                externalNumber={externalNumber}
                dialpadProceedButtonDisabled={dialpadProceedButtonDisabled}
                callForwardDialpadClassName={classes.callForwardDialpad}
                contentClassName={classes.content}
                inputWrapperClassName={classes.inputWrapper}
                dialpadButtonContainerClassName={classes.dialpadButtonContainer}
                contactRowListClassName={classes.contactRowList}
                showBackSpace={!!externalNumber}
                onDialpadClicked={onUseDialpadClicked}
                onNumberChange={onCallForwardNumberChange}
                onDialpadNumberPressed={onCallForwardNumberPressed}
                onBackSpaceClicked={onCallForwardBackSpaceClicked}
                onBackButtonClicked={onCallForwardBackButtonClicked}
                onDialpadProceed={onDialpadProceed}
              />
            )}
          </ThemedCardContent>
        </ThemedCard>
        {showCallCenterButton && (
          <Buttons.CallActionButton
            id={`${id}-Call-Center-Button`}
            theme={CallButtonTheme.dark}
            className={classes.externalCallActionButton}
            endIcon={<FontAwesomeIcon icon={faArrowRight} />}
            onClick={onGoToCallCenter}
          >
            Go to Call Center
          </Buttons.CallActionButton>
        )}
      </div>
    </div>
  );
});

NavigationDiallerComponent.defaultProps = {
  className: undefined,
  style: undefined,
  contactNumbers: undefined,
  moreNumbers: undefined,
  showMoreNumber: false,
  moreNumberOpened: false,
  onDropDownSelect: () => {},
  showBackSpace: false,
  callButtonDisabled: false,
  dialpadButtonDisabled: false,
  recordingButtonDisabled: false,
  number: '',
  showDialpad: false,
  isMicMute: false,
  isOnHold: false,
  callState: CallState.dialing,
  isCallRecording: false,
  callTime: 'Not Connected',
  contactName: '',
  contactCount: 0,
  showCallCenterButton: false,
  onCallClicked: () => {},
  onHangUp: () => {},
  onCallRecord: () => {},
  onAddBookmark: () => {},
  onDialpadClicked: () => {},
  onCallHold: () => {},
  onCallMute: () => {},
  onGoToCallCenter: () => {},
  onClose: () => {},
  menuZIndex: 1300 as any,
  hideNumberPad: false,
  proceedButtonDisabled: false,
  inputValue: '',
  onInputChange: () => {},
  onCallForwardCardClose: () => {},
  onProceed: () => {},
  dataLoading: false,
  onSelect: () => {},
  data: [],
  dataTotalCounts: undefined,
  dialpadProceedButtonDisabled: false,
  onCallForwardNumberChange: () => {},
};

export { CallState as NavigationDiallerCallState } from '../../Enums/Call.types';
export default NavigationDiallerComponent;
