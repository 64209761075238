import React, { forwardRef, useCallback } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import classnames from 'classnames';

import Icon, { IconTheme } from '../Icon';

import { MenuItemProps, Theme } from './MenuItem.types';
import MenuItemThemer from './MenuItem.theme';

const ThemedMenuItem = withStyles(MenuItemThemer.classes)(MenuItem);

const MenuItemComponent = forwardRef<HTMLLIElement, MenuItemProps>((props, ref) => {
  const {
    id,
    className,
    style,
    theme,
    value,
    leftIcon,
    leftIconClassName,
    leftIconStyle,
    children,
    callbackValue,
    onClick,
    fullWidthText,
    selected,
    disabled,
  } = props;
  const classes = MenuItemThemer.useStyles();
  const themeClassNames = classnames(
    classes.default,
    { [classes.danger]: theme === Theme.danger },
    { [classes.dark]: theme === Theme.dark },
    className
  );
  const iconClassNames = classnames(
    classes.icon,
    { [classes.iconDanger]: theme === Theme.danger },
    { [classes.iconDark]: theme === Theme.dark },
    leftIconClassName
  );
  const checkIconTheme = theme === Theme.dark ? IconTheme.light : IconTheme.secondary;

  const handleClick = useCallback(
    (e: any) => {
      onClick?.(e, callbackValue);
    },
    [onClick, callbackValue]
  );

  return (
    <ThemedMenuItem
      id={id}
      ref={ref}
      className={themeClassNames}
      style={style}
      value={value}
      onClick={handleClick}
      disabled={disabled}
      disableGutters
      disableRipple
      disableTouchRipple
    >
      {leftIcon && (
        <ListItemIcon className={iconClassNames} style={leftIconStyle}>
          {leftIcon}
        </ListItemIcon>
      )}

      <ListItemText disableTypography>
        {children}

        {selected && !fullWidthText && (
          <Icon id={`${id}-SelectedIcon`} className={classnames(classes.selectionWithPadding)} theme={checkIconTheme}>
            <FontAwesomeIcon icon={faCheck} />
          </Icon>
        )}
      </ListItemText>

      {selected && fullWidthText && (
        <Icon id={`${id}-SelectedIcon`} theme={checkIconTheme}>
          <FontAwesomeIcon icon={faCheck} />
        </Icon>
      )}
    </ThemedMenuItem>
  );
});

MenuItemComponent.defaultProps = {
  theme: Theme.normal,
  className: undefined,
  style: undefined,
  leftIconClassName: undefined,
  leftIconStyle: undefined,
  leftIcon: undefined,
  onClick: () => null,
  callbackValue: undefined,
  fullWidthText: false,
  selected: false,
  disabled: false,
};

export { Theme as MenuItemTheme } from './MenuItem.types';
export default MenuItemComponent;
