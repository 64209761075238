import { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import { addAuthInfo } from '../slices/auth';


import { useExternalScript } from "./useExternalScript";

export const useMetaDataLoad = () => {
  const [errorCode, setErrorCode] = useState<number>(0);
  const [fetched, setFetched] = useState(false);
  const [loading, setLoading] = useState(true);


  const dispatch = useDispatch();
  let orgBaseUrl = null;
  
  if (window?.location?.ancestorOrigins) {
    orgBaseUrl = window?.location?.ancestorOrigins[0];
  } else if (window.location.search) {
    let urlParams = new  URLSearchParams(window.location.search);
    let paramsObj = {};
    // @ts-ignore
    urlParams?.forEach((v,k)=> paramsObj[k] = v);
    // @ts-ignore
    orgBaseUrl = paramsObj?.sfdcIframeOrigin;
  } else {
    orgBaseUrl = null;
  }

  const openCTIState = useExternalScript(orgBaseUrl ? orgBaseUrl + '/support/api/58.0/lightning/opencti_min.js' : null);
  const integrationState = useExternalScript(orgBaseUrl ? orgBaseUrl + '/support/console/58.0/integration.js' : null);


  const saveAuthInfo = useCallback((authInfo: any) => {
    const token = authInfo?.AccessToken;
    const decoded: any = jwt_decode(token);
    const userId = decoded.sub;
    const tenantCode = decoded.website;
    let rtaspUrl = '';

    if (authInfo?.RPAPIGW_URL === 'https://prodapigw.rocketphone.ai') {
      rtaspUrl = 'wss://prodrtasp.rocketphone.ai/ws/v1/process';
    } else if (authInfo?.RPAPIGW_URL === 'https://stapigw.rocketphone.ai') {
      rtaspUrl = 'wss://strtasp.rocketphone.ai/ws/v1/process';
    }  else if (authInfo?.RPAPIGW_URL === 'https://qaapigw.rocketphone.ai') {
      rtaspUrl = 'wss://qartasp.rocketphone.ai/ws/v1/process';
    } else {
      rtaspUrl = 'wss://devrtasp.rocketphone.ai/ws/v1/process';
    }

    const processedAuthData = { ...authInfo, userId, tenantCode, rtaspUrl };

    dispatch(addAuthInfo(processedAuthData));
  },[dispatch]);

  useEffect(() => {
    if (openCTIState === 'error') {
      setErrorCode(100);
    } else {
      if (openCTIState === 'ready' && integrationState === 'ready') {

        // @ts-ignore
        (sforce as any)?.console.setCustomConsoleComponentPopoutable(false);

        // @ts-ignore
        sforce.opencti.runApex({
          apexClass: 'rocketphone.RPGetLoggedInUserInfo',
          methodName: 'getUserInfo',
          methodParams: '',
          callback: (response: any) => {
            if (response.success) {
              const authInfo = JSON.parse(response.returnValue.runApex) as  AuthInfo
              saveAuthInfo(authInfo);
              setErrorCode(0);
              setLoading(false);
              setFetched(true);
            } else {
              setFetched(false);
              setLoading(false);
              setErrorCode(401);
            }
          }
        });
     }
    }
  }, [openCTIState, integrationState, saveAuthInfo]);
   
  return [errorCode, loading, fetched];
};

export type AuthInfo = {
  UserId: string;
  Username: string;
  Email: string;
  FullName: string,
  OrgId: string,
  OrgName: string,
  RPAPIGW_URL: string,
  StatusCode: string,
  Status: number,
  AccessToken: string,
  RefreshToken: string,
  ExpiresIn: number,
  TokenType: string,
};