import CallCentre from '../CallCentre/CallCentre';

export default class CallRecordService {
    // static getCallContactNumber(callRecord) {
  //   const { callDirection, callEvents } = callRecord;
  //   const callEvent = callEvents?.[0];
  //   let result = null;

  //   if (callEvent && callDirection === CallActivityDirectionEnum.incoming) {
  //     result = callEvent.from;
  //   } else if (callEvent && callDirection === CallActivityDirectionEnum.outgoing) {
  //     result = callEvent.to;
  //   }

  //   return result;
  // }

  static async finalizeCallRecord(tenantCode: string, token: string, finalizeRequest: any) {
    const { callRecordId, reason, topic, callOutcome, callDuration } = finalizeRequest;
    const request = { reason, topic, callOutcome, callDuration };

    if (callRecordId) {
      try {
        const response = await CallCentre.callRecordFinalize(tenantCode, token, callRecordId, request);

        // We have to get the callevents to refresh the dialler campaign page
        // We can vitness this when we initiate and wrapup the call from dialler campaign
        // if (response?.data?.campaignId) {
        //   const callEventRequest =
        //     new VIQModels.CallEvent.GetCampaignCallEventsRequest(response?.data?.campaignId, CampaignConstants.EventStatus.Upcoming);
        //   callEventRequest.skip = 0;
        //   callEventRequest.orderBy = 'scheduledTime';
        //   callEventRequest.orderDirection = 'desc';
        //   callEventRequest.refresh = true;

        //   store.dispatch(getCampaignScheduledCalls(callEventRequest));
        //   store.dispatch(getCampaignContacts(callEventRequest));
        //   store.dispatch(getCampaignMissedAppointments(callEventRequest));
        // }

        return response.data;
      } catch (error) {
        throw error;
      }
    } else {
      return null;
    }
  }
}
