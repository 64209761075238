import React, { forwardRef, useCallback } from 'react';
import Card from '@material-ui/core/Card';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faHashtag } from '@fortawesome/pro-solid-svg-icons';

import Numberpad from '../Private/NumberPad';
import DiallerButton from '../Private/DiallerButton';

import { DialpadProps } from './Dialpad.type';
import DialpadThemer from './Dialpad.theme';
import { useLongPress } from './Dialpad.hooks';

const itemPlaceholders = Array.from(new Array(9));

const defaultOptions = {
  shouldPreventDefault: true,
  delay: 500,
};

const DialpadComponent = forwardRef<HTMLElement, DialpadProps>((props, ref) => {
  const { id, className, style, number, onChange, showBackSpace, onBackSpaceClicked, callBackValue, onNumberChange } = props;
  const classes = DialpadThemer.useStyles();
  const cardClassName = classnames(classes.card, className);
  const diallerButtonClassName = classnames(classes.diallerButton);
  const diallerButtonAreaClassName = classnames(classes.buttonArea);

  const handleChange = useCallback(
    (value: any, e: any) => {
      e.persist();
      onChange?.(value, e);
    },
    [onChange]
  );

  const handleBackSpaceClick = useCallback(
    (value: any, e: any) => {
      onBackSpaceClicked?.(value, e);
    },
    [onBackSpaceClicked, callBackValue]
  );

  const longPressEvent = useLongPress(handleChange, handleChange, defaultOptions, '0', '+');

  return (
    <Card id={id} ref={ref} className={cardClassName} style={style}>
      <Numberpad
        id={`${id}-numberpad`}
        number={number}
        onBackspaceClick={handleBackSpaceClick}
        callbackValue="backspace"
        showBackSpace={!!showBackSpace}
        onNumberChange={onNumberChange}
      />
      <div className={diallerButtonAreaClassName}>
        {itemPlaceholders.map((item, index) => (
          <DiallerButton
            id={`${id}-DialButton-${index + 1}`}
            key={`${id}-DialButton-${index + 1}`}
            className={diallerButtonClassName}
            callbackValue={`${index + 1}`}
            onClick={handleChange}
          >
            {index + 1}
          </DiallerButton>
        ))}
        <DiallerButton id={`${id}-DialButton-*`} className={diallerButtonClassName} callbackValue="*" onClick={handleChange}>
          <FontAwesomeIcon icon={faAsterisk} />
        </DiallerButton>
        <DiallerButton id={`${id}-DialButton-0`} isSubscript className={diallerButtonClassName} callbackValue="0" {...longPressEvent}>
          0
        </DiallerButton>
        <DiallerButton id={`${id}-DialButton-#`} className={diallerButtonClassName} callbackValue="#" onClick={handleChange}>
          <FontAwesomeIcon icon={faHashtag} />
        </DiallerButton>
      </div>
    </Card>
  );
});

DialpadComponent.defaultProps = {
  className: undefined,
  style: undefined,
  number: undefined,
  showBackSpace: false,
  onChange: () => null,
  onNumberChange: () => null,
};

export default DialpadComponent;
