import { makeStyles } from '@material-ui/core/styles';

import FontFamily from '../../Theme/FontFamily';

import { } from './MessageBanner.types';

const useStyles = makeStyles({
parentBanner: {
    minHeight: '34px',
    transition: 'all 5s ease',
    opacity: 1,
    // display: 'block',
  },

  hideBar: {
    opacity: 0,
    display: 'none',
  },

  messageContainer: {
    zIndex: 1201,
    display: 'flex',
    minHeight: '35px',
    height: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FF5B60',

    // '&.warnBackground': {
    //   background: '#FF5B60',
    // },
    // '&.errorBackground': {
    //   background: '#e4253c',
    // },
    // '&.infoBackground': {
    //   background: '#e3f1fd',
    // },
  },

  btnText: {
    color: '#ffffff',
    fontFamily: FontFamily,
    fontSize: '1.4rem',
    fontWeight: 'bold',
    letterSpacing: '0.023rem',
    linHeight: '1.7rem',
  },

  rightSec: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: '65px',
    zIndex: 1,
    button: {
      marginLeft: '1.2rem',
      minWidth: '1.2rem',
      width: '1.2rem',
      padding: 0,
      '&:hover': {
        background: 'transparent',
      }
    },
    svg: {
      color: '#ffffff',
      fontSize: '1.2rem',
    },
  },
});

export default { useStyles };
