import React, { forwardRef, useCallback } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

import Avatar from '../../../Avatar';
import Typography, { TypographyVariant } from '../../../Typography';

import { ContactRowProps, ContactStatus } from './ContactRow.types';
import ContactRowThemer from './ContactRow.theme';

const ContactRowComponent: React.FC<ContactRowProps> = forwardRef<HTMLDivElement, ContactRowProps>((props, ref) => {
  const {
    id,
    className,
    style,
    dataItem,
    contactName,
    avatarUrl,
    avatarFallbackLabel,
    contactStatus,
    selected,
    onSelect,
    callbackvalue,
    active,
  } = props;
  const classes = ContactRowThemer.useStyles(props);
  const rowClass = classnames(className, classes.row, { inActive: !active });
  const contactStatusClass = classnames(classes.status, contactStatus);
  const iconClass = classnames(classes.icon);
  const avatarClass = classnames(classes.avatar, { selectedAvatar: selected });

  const handleSelect = useCallback(
    (e: any) => {
      onSelect?.(dataItem);
    },
    [onSelect, callbackvalue]
  );

  return (
    <div id={`${id}-contactRow}`} className={rowClass} style={style} ref={ref} onClick={handleSelect}>
      <Avatar
        id={`${id}-contactRow-Avatar`}
        className={avatarClass}
        url={avatarUrl}
        fallbackLabel={avatarFallbackLabel}
        alt="Contact Avatar"
      />
      <Typography.H4 variant={TypographyVariant.light}>{contactName}</Typography.H4>
      <Typography.H5 variant={TypographyVariant.light} className={contactStatusClass}>
        {contactStatus}
      </Typography.H5>
      {selected && <FontAwesomeIcon icon={faCheck} className={iconClass} />}
    </div>
  );
});

ContactRowComponent.defaultProps = {
  className: undefined,
  style: undefined,
  contactName: undefined,
  selected: false,
  active: false,
  avatarFallbackLabel: 'NA',
};

export const ContactPersonStatus = ContactStatus;
export default ContactRowComponent;
