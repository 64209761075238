import React, { forwardRef, useCallback } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/pro-regular-svg-icons';
import { faSave } from '@fortawesome/pro-solid-svg-icons';
import { faDotCircle } from '@fortawesome/pro-regular-svg-icons';

import Typography, { TypographyVariant } from '../Typography';

import { SelectionCardProps } from './CallOutcomeCard.types';
import SelectionCardThemer from './CallOutcomeCard.theme';

const ThemedCard = withStyles(SelectionCardThemer.classes)(Card);
const ThemedCardContent = withStyles(SelectionCardThemer.cardContentClasses)(CardContent);
const ThemedRadio = withStyles(SelectionCardThemer.radioButtonClasses)(Radio);
const ThemedFormControlLabel = withStyles(SelectionCardThemer.formControlLabelClasses)(FormControlLabel);

const renderFormLabel = (label: any, isDeletedDisposition: boolean = false) => {
  const dispositionTextLabel = !isDeletedDisposition 
    ? <Typography.P1 id={`${label}-Form-Control-label`}>{label}</Typography.P1> 
    : <>
      <Typography.P1 id={`${label}-Form-Control-label`}>{label}</Typography.P1>
      <Typography.P2 id={`${label}-Form-Control-label`} style={{ paddingLeft: '5px' }}>(Deleted)</Typography.P2>
    </>
  return dispositionTextLabel;
};

const SelectionCardComponent = forwardRef<HTMLElement, SelectionCardProps<any>>((props, ref) => {
  const {
    id,
    className, style,
    icon, showIcon,
    cardTitle,
    selectionItems, getItemLabel, getItemValue, onItemSelect,
    showDivider, callOutcome,
    readOnly,
    dividerIndex,
    warningText
  } = props;

  const [value, setValue] = React.useState(callOutcome);

  const handleChange = useCallback((event: any) => {
    setValue(event.target.value);
    onItemSelect?.(event.target.value);
  }, [onItemSelect]);

  const classes = SelectionCardThemer.useStyles();

  const cardClassName = classnames(classes.card, className);

  return (
    <ThemedCard id={id} ref={ref} className={cardClassName} style={style}>
      <ThemedCardContent id={`${id}-Card-Content`}>
        <div className={classes.titleContainer}>
          {showIcon && <div className={classes.iconContainer}>{icon}</div>}
          <div className={classes.textContainer}>
            <Typography.H3 variant={TypographyVariant.active} className={classes.titleText}>
              {cardTitle}
            </Typography.H3>
            <Typography.P1 variant={TypographyVariant.active}>(select one)</Typography.P1>
          </div>
        </div>

        {warningText && 
          <div className={classnames(classes.wariningTextContainer)}>
            <Typography.P2 id={`${id}-Warning-Text`} variant={TypographyVariant.error}>{warningText}</Typography.P2>
          </div>
        }
        
        <div className={classes.bodyContainer}>
          <FormControl id={`${id}-Form-Control`} component="fieldset" className={classes.formControlContainer}>
            <RadioGroup aria-label="selectionItems" name="selectionItems-radios" value={value} onChange={handleChange}>
              {selectionItems.map((item, index) => (
                <div key={`${id}-Selection-Item-${index + 1}`} className={classes.outcome}>
                  <ThemedFormControlLabel
                    value={getItemValue(item)}
                    control={
                      <ThemedRadio
                        disableRipple
                        icon={<FontAwesomeIcon icon={faCircle} className={classes.icon} />}
                        checkedIcon={<FontAwesomeIcon icon={faDotCircle} className={classes.iconChecked} />}
                        disabled={!!readOnly}
                      />
                    }
                    label={renderFormLabel(getItemLabel(item), item?.isDeletedDisposition)}
                  />
                  {showDivider && index === dividerIndex && <Divider />}
                </div>
              ))}
            </RadioGroup>
          </FormControl>
        </div>
      </ThemedCardContent>
    </ThemedCard>
  );
});

SelectionCardComponent.defaultProps = {
  className: undefined,
  style: undefined,
  cardTitle: 'Selection Card',
  showIcon: false,
  selectionItems: [],
  getItemLabel: () => {},
  getItemValue: () => {},
  onItemSelect: () => {},
  showDivider: false,
  callOutcome: '',
  dividerIndex: 0,
  readOnly: false,
};
export default SelectionCardComponent;
